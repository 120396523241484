import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./Products.css";
import { useEffect } from "react";
import i18next from "i18next";
import { Meta } from "../../Components/Helpers/Meta";

const Products = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const {lang} =useParams();

  
  // useEffect(() => {
  //   if (lang) {
  //       i18next.changeLanguage(lang);
  //   }
  //   const updateTitle = () => {
  //       document.title = `${t("products")} - Cevher`;
  //     };
    
  //     updateTitle();
    
  //     const handleLanguageChanged = () => {
  //       updateTitle();
  //     };
    
  //     i18next.on("languageChanged", handleLanguageChanged);
    
  //     return () => {
  //       i18next.off("languageChanged", handleLanguageChanged);
  //     };
  //   }, [lang]);
  return (
    <>
    <Meta title={t("products")} description={t("corporate-clients-1")} lang={lang}/>
      <Navbar />
      <div className="c-container">
        <h1 className="c-products-title">{t("products")}</h1>
        <div className="c-products-list">
          <a href="/products/oem" className="c-products-list-item">
            <img
              className="c-products-list-item-img"
              src={require("../../assets/Products/alloywheelsforoem.jpg")}
              alt="OEM"
            />
            <div className="c-products-list-item-title">
              {t("alloywheelsforoem")}
            </div>
          </a>
          <a href="/products/after-market" className="c-products-list-item">
            <img
              className="c-products-list-item-img"
              src={require("../../assets/Products/alloywheelsforaftermarket.jpg")}
              alt="AFM"
            />
            <div className="c-products-list-item-title">
              {t("alloywheelsforaftermarket")}
            </div>
          </a>
          <a
            href="/products/chassis-and-powertrain"
            className="c-products-list-item"
          >
            <img
              className="c-products-list-item-img"
              src={require("../../assets/Products/chassisandpowertrain.jpg")}
              alt="Chassis"
            />
            <div className="c-products-list-item-title">
              {t("chassisandpowertrain")}
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Products;
