import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import { Footer } from "./Components/Footer/Footer";

import About from "./Pages/About";
import Customers from "./Pages/Customers";
import Home from "./Pages/Home";
import OEM from "./Pages/OEM";
import Social from "./Pages/SocialResponsibility";
import Values from "./Pages/Values";
import AfterMarket from "./Pages/AfterMarket";
import CaP from "./Pages/Chassis";
import RND from "./Pages/RND";
import Quality from "./Pages/Quality";
import Production from "./Pages/Production";
import IndustrialDesign from "./Pages/IndustrialDesign";
import Enviromental from "./Pages/Environmental";
import EnvAndEnergy from "./Pages/EnvAndEnergy";
import ISO from "./Pages/ISO";
import Energy from "./Pages/Energy";
import SocialCompliance from "./Pages/SocialComplience";
import Health from "./Pages/Health";
import Security from "./Pages/Security";
import Protection from "./Pages/Protection";
import SustainabilityReport from "./Pages/SustainabilityReport";
import GreenPurchase from "./Pages/GreenPurchase";
import Career from "./Pages/Career";
import News from "./Pages/News";
import New from "./Pages/NewPage";
import { useEffect } from "react";
import Contact from "./Pages/Contact";
import History from "./Pages/History";
import Products from "./Pages/Products";
import { StudentProgram } from "./Pages/StudentPrograms/StudentPrograms";
import ModernSlaveryPolicy from "./Pages/ModernSlavery";
import SupplierSustainability from "./Pages/SupplierSustaninability";
import InformationCommunity from "./Pages/InformationCommunity";
import PublicOfferInfo from "./Pages/PublicOfferInfo";


function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/corporate/about" element={<About />} />
        <Route path="/:lang/kurumsal/hakkimizda" element={<About />} />
        <Route path="/corporate/history" element={<History />} />
        <Route path="/:lang/kurumsal/tarihce" element={<History />} />
        <Route path="/corporate/clients" element={<Customers />} />
        <Route path="/:lang/kurumsal/musterilerimiz" element={<Customers />} />
        <Route path="/corporate/values" element={<Values />} />
        <Route path="/:lang/kurumsal/degerlerimiz" element={<Values />} />
        <Route path="/corporate/information-community-system" element={<InformationCommunity />} />
        <Route path="/:lang/kurumsal/bilgi-toplum-sistemi" element={<InformationCommunity />} />
        <Route path="/corporate/:link" element={<New />} />
        <Route path="/:lang/kurumsal/:link" element={<New />} />
        <Route path="/products" element={<Products />} />
        <Route path="/:lang/urunler" element={<Products />} />
        <Route path="/products/oem" element={<OEM />} />
        <Route path="/:lang/urunler/oem" element={<OEM />} />
        <Route path="/products/social-responsibility" element={<Social />} />
        <Route path="/:lang/urunler/sosyal-sorumluluk" element={<Social />} />
        <Route path="/products/after-market" element={<AfterMarket />} />
        <Route path="/:lang/urunler/after-market" element={<AfterMarket />} />
        <Route path="/products/chassis-and-powertrain" element={<CaP />} />
        <Route path="/:lang/urunler/sasi-ve-powertrain" element={<CaP />} />
        <Route path="/products/:link" element={<New />} />
        <Route path="/:lang/urunler/:link" element={<New />} />
        <Route
          path="/development-and-production/rnd-engineering"
          element={<RND />}
        />
        <Route
          path="/:lang/gelistirme-ve-uretim/arge-ve-muhendislik"
          element={<RND />}
        />
        <Route
          path="/development-and-production/quality"
          element={<Quality />}
        />
        <Route
          path="/:lang/gelistirme-ve-uretim/kalite"
          element={<Quality />}
        />
        <Route
          path="/development-and-production/production"
          element={<Production />}
        />
        <Route
          path="/:lang/gelistirme-ve-uretim/uretim"
          element={<Production />}
        />
        <Route path="/development-and-production/:link" element={<New />} />
        <Route path="/:lang/gelistirme-ve-uretim/:link" element={<New />} />
        <Route path="/industrial-design" element={<IndustrialDesign />} />
        <Route path="/:lang/endustriyel-tasarim" element={<IndustrialDesign />} />
        <Route
          path="/sustainability/environment-and-energy-policy"
          element={<EnvAndEnergy/>}
        />
        <Route
          path="/:lang/surdurulebilirlik/cevre-ve-enerji-politikasi"
          element={<EnvAndEnergy/>}
        />
        <Route
          path="/sustainability/environmental-policy-and-activities"
          element={<Enviromental />}
        />
        <Route
          path="/:lang/surdurulebilirlik/cevre-politikasi-ve-aktiviteleri"
          element={<Enviromental />}
        />
        <Route path="/sustainability/iso-14001-2015-scope" element={<ISO />} />
        <Route path="/:lang/surdurulebilirlik/iso-14001-2015-kapsami" element={<ISO />} />
        <Route
          path="/sustainability/energy-management-policy"
          element={<Energy />}
        />
        <Route
          path="/:lang/surdurulebilirlik/enerji-yonetimi-politikasi"
          element={<Energy />}
        />
        <Route
          path="/sustainability/green-purchasing"
          element={<GreenPurchase />}
        />
         <Route
          path="/:lang/surdurulebilirlik/yesil-satin-alma"
          element={<GreenPurchase />}
        />
        <Route
          path="/sustainability/occupational-health-and-safety-policy-and-activities"
          element={<Health />}
        />
        <Route
          path="/:lang/surdurulebilirlik/is-sagligi-ve-guvenligi-politikasi-ve-aktiviteleri"
          element={<Health />}
        />
        <Route
          path="/sustainability/social-compliance-policy"
          element={<SocialCompliance />}
        />
        <Route
          path="/:lang/surdurulebilirlik/sosyal-uygunluk-politikasi"
          element={<SocialCompliance />}
        />
        <Route
          path="/sustainability/modern-slavery-policy"
          element={<ModernSlaveryPolicy/>}
        />
        <Route
          path="/:lang/surdurulebilirlik/modern-kolelik-politikasi"
          element={<ModernSlaveryPolicy/>}
        />
        <Route
          path="/sustainability/information-security-policy"
          element={<Security />}
        />
        <Route
          path="/:lang/surdurulebilirlik/bilgi-guvenligi-politikasi"
          element={<Security />}
        />
        <Route
          path="/sustainability/protection-of-personal-data"
          element={<Protection />}
        />
        <Route
          path="/:lang/surdurulebilirlik/kisisel-verilerin-korunmasi"
          element={<Protection />}
        />
        <Route
          path="/sustainability/sustainability-report"
          element={<SustainabilityReport />}
        />
        <Route
          path="/:lang/surdurulebilirlik/surdurulebilirlik-raporu"
          element={<SustainabilityReport />}
        />
        <Route path="/sustainability/supplier-sustainability-policy" element={<SupplierSustainability />} />
        <Route path="/:lang/surdurulebilirlik/tedarikci-surdurulebilirlik-politikasi" element={<SupplierSustainability />} />
        <Route path="/sustainability/:link" element={<New />} />
        <Route path="/:lang/surdurulebilirlik/:link" element={<New />} />
        <Route path="/career" element={<Career />} />
        <Route path="/:lang/kariyer" element={<Career />} />
        <Route path="/student-programs" element={<StudentProgram />} />
        <Route path="/:lang/ogrenci-programlari" element={<StudentProgram />} />
        <Route path="/news" element={<News />} />
        <Route path="/:lang/haberler" element={<News />} />
        <Route path="investor-relations/initial-public-offering" element={<PublicOfferInfo />} />
        <Route path="/:lang/yatirimci-iliskileri/halka-arz" element={<PublicOfferInfo />} />
        <Route path="/investor-relations/:link" element={<New />} />
        <Route path="/:lang/yatirimci-iliskileri/:link" element={<New />} />

        <Route path="/:link" element={<New />} />
        <Route path="/:lang/:link" element={<New />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/:lang/iletisim" element={<Contact />} />
        <Route
          path="*"
          element={<Navigate to="/" />}
          component={<div>404</div>}
        />
        
      </Routes>
      <Footer />
    </>
  );
}

// export const App_TR = () => {
//   return (
//     <>
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/corporate/about" element={<About />} />
//         <Route path="/corporate/history" element={<History />} />
//         <Route path="/corporate/clients" element={<Customers />} />
//         <Route path="/corporate/values" element={<Values />} />
//         <Route path="/corporate/information-community-system" element={<InformationCommunity />} />
//         <Route path="/products" element={<Products />} />
//         <Route path="/products/oem" element={<OEM />} />
//         <Route path="/products/social-responsibility" element={<Social />} />
//         <Route path="/products/after-market" element={<AfterMarket />} />
//         <Route path="/products/chassis-and-powertrain" element={<CaP />} />
//         <Route
//           path="/development-and-production/rnd-engineering"
//           element={<RND />}
//         />
//         <Route
//           path="/development-and-production/quality"
//           element={<Quality />}
//         />
//         <Route
//           path="/development-and-production/production"
//           element={<Production />}
//         />
//         <Route path="/industrial-design" element={<IndustrialDesign />} />
//         <Route
//           path="/sustainability/environmental-policy-and-activities"
//           element={<Enviromental />}
//         />
//         <Route path="/sustainability/iso-14001-2015-scope" element={<ISO />} />
//         <Route
//           path="/sustainability/energy-management-policy"
//           element={<Energy />}
//         />
//         <Route
//           path="/sustainability/occupational-health-and-safety-policy-and-activities"
//           element={<Health />}
//         />
//         <Route
//           path="/sustainability/information-security-policy"
//           element={<Security />}
//         />
//         <Route
//           path="/sustainability/protection-of-personal-data"
//           element={<Protection />}
//         />
//         <Route
//           path="/sustainability/sustainability-report"
//           element={<SustainabilityReport />}
//         />
//         <Route path="/career" element={<Career />} />
//         <Route path="/news" element={<News />} />
//         <Route path="investor-relations/initial-public-offering" element={<PublicOfferInfo />} />
//         <Route path="/:link" element={<New />} />
//         <Route path="/iletisim" element={<Contact />} />
//         <Route
//           path="*"
//           element={<Navigate to="/" />}
//           component={<div>404</div>}
//         />
//       </Routes>
//       <Footer />
//     </>
//   );
// };

export default App;
