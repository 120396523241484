import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./Protection.styles.css";
import "../Protection/Protection.styles.css";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { Meta } from "../../Components/Helpers/Meta";

const Protection = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const {lang} =useParams();

  
  // useEffect(() => {
  //   if (lang) {
  //       i18next.changeLanguage(lang);
  //   }
  //   const updateTitle = () => {
  //       document.title = `${t("protectionofpersonaldata")} - Cevher`;
  //     };
    
  //     updateTitle();
    
  //     const handleLanguageChanged = () => {
  //       updateTitle();
  //     };
    
  //     i18next.on("languageChanged", handleLanguageChanged);
    
  //     return () => {
  //       i18next.off("languageChanged", handleLanguageChanged);
  //     };
  //   }, [lang]);

  return (
    <div>
      <Meta title={t("protectionofpersonaldata")} description={t("sustainability-protection-1")} lang={lang}/>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/protection-of-personal-data.e5691f2e.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("sustainability")}
              </div>
              <h1 className="c-caption-header">
                {t("protectionofpersonaldata")}
              </h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <p>
            <strong style={{ textAlign: "start" }}>
              {t("sustainability-protection-1")}
            </strong>
            <br />
            <br />
            {t("sustainability-protection-2")}
            <br />
            {t("sustainability-protection-3")}
            <br />
            <br />
            <ul style={{ listStyle: "inside" }}>
              <li>{t("sustainability-protection-regulations-1")}</li>
              <li>{t("sustainability-protection-regulations-2")}</li>
              <li>{t("sustainability-protection-regulations-3")}</li>
              <li>{t("sustainability-protection-regulations-4")}</li>
              <li>{t("sustainability-protection-regulations-5")}</li>
            </ul>
          </p>
          <p>
            {t("sustainability-protection-6")}
            <br />
            <br />
          </p>
          <p>
            {t("sustainability-protection-law")}
            <ol>
              <li>{t("sustainability-protection-law-1")}</li>
              <li>{t("sustainability-protection-law-2")}</li>
              <li>{t("sustainability-protection-law-3")}</li>
              <li>{t("sustainability-protection-law-4")}</li>
              <li>{t("sustainability-protection-law-5")}</li>
              <li>{t("sustainability-protection-law-6")}</li>
              <li>{t("sustainability-protection-law-7")}</li>
              <li>{t("sustainability-protection-law-8")}</li>
              <li>
                {t("sustainability-protection-law-9")}
                <br />
                &nbsp;
              </li>
            </ol>
          </p>
          <p>
            <strong style={{ textAlign: "start" }}>
              {t("sustainability-protection-clarification")}
            </strong>
            <ul className="c-protection-list">
              <li>
                <p>
                  <strong style={{ textAlign: "start" }}>
                    {t("sustainability-protection-clarification-1-title")}
                  </strong>
                  <br />
                  <br />
                  {t("sustainability-protection-clarification-1-desc")}
                </p>
              </li>
              <li>
                <p>
                  <strong style={{ textAlign: "start" }}>
                    {t("sustainability-protection-clarification-2-title")}
                  </strong>
                  <br />
                  <br />
                  {t("sustainability-protection-clarification-2-desc")}
                  <ul className="c-protection-list-insidelist">
                    <li>
                      {t("sustainability-protection-clarification-2-desc-1")}
                    </li>
                    <li>
                      {t("sustainability-protection-clarification-2-desc-2")}
                    </li>
                    <li>
                      {t("sustainability-protection-clarification-2-desc-3")}
                    </li>
                    <li>
                      {t("sustainability-protection-clarification-2-desc-4")}
                    </li>
                    <li>
                      {t("sustainability-protection-clarification-2-desc-5")}
                    </li>
                  </ul>
                </p>
              </li>
              <li>
                <p>
                  <strong style={{ textAlign: "start" }}>
                    {t("sustainability-protection-clarification-3-title")}
                  </strong>
                </p>
                {t("sustainability-protection-clarification-3-desc")}
              </li>
              <li>
                <p>
                  <strong style={{ textAlign: "start" }}>
                    {t("sustainability-protection-clarification-4-title")}
                  </strong>
                </p>
                {t("sustainability-protection-clarification-4-desc")}
              </li>
              <li>
                <p>
                  <strong style={{ textAlign: "start" }}>
                    {t("sustainability-protection-clarification-5-title")}
                  </strong>
                </p>
                {t("sustainability-protection-clarification-5-desc")}
              </li>
            </ul>
          </p>
          <p>
            <strong style={{ textAlign: "start" }}>
              {t("sustainability-protection-camera")}
            </strong>
            <ul className="c-protection-list">
              <li>
                <p>
                  <strong style={{ textAlign: "start" }}>
                    {t("sustainability-protection-camera-1-title")}
                  </strong>
                  <br />
                  {t("sustainability-protection-camera-1-desc")}
                </p>
              </li>
              <li>
                <p>
                  <strong style={{ textAlign: "start" }}>
                    {t("sustainability-protection-camera-2-title")}
                  </strong>
                  <br />
                  {t("sustainability-protection-camera-2-desc")}
                </p>
              </li>
              <li>
                <p>
                  <strong style={{ textAlign: "start" }}>
                    {t("sustainability-protection-camera-3-title")}
                  </strong>
                  <br />
                  {t("sustainability-protection-camera-3-desc")}
                </p>
              </li>
              <li>
                <p>
                  <strong style={{ textAlign: "start" }}>
                    {t("sustainability-protection-camera-4-title")}
                  </strong>
                  <br />
                  {t("sustainability-protection-camera-4-desc")}
                </p>
              </li>
              <li>
                <p>
                  <strong style={{ textAlign: "start" }}>
                    {t("sustainability-protection-camera-5-title")}
                  </strong>
                  <br />
                  {t("sustainability-protection-camera-5-desc")}
                </p>
              </li>
            </ul>
          </p>
{/*           <div>
            <div className="c-col">
              <a
                href={
                  "https://www.cevher.com/static/docs/cevher_kvkk_form.pdf"
                }
                className="c-button"
                style={{ marginTop: "1.5rem" }}
              >
                {t("sustainability-protection-kvkk-request")}
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Protection;
