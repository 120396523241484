import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { Meta } from "../../Components/Helpers/Meta";

const GreenPurchase = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const {lang} =useParams();

  
  // useEffect(() => {
  //   if (lang) {
  //       i18next.changeLanguage(lang);
  //   }
  //   const updateTitle = () => {
  //       document.title = `${t("green-purchasing-policy")} - Cevher`;
  //     };
    
  //     updateTitle();
    
  //     const handleLanguageChanged = () => {
  //       updateTitle();
  //     };
    
  //     i18next.on("languageChanged", handleLanguageChanged);
    
  //     return () => {
  //       i18next.off("languageChanged", handleLanguageChanged);
  //     };
  //   }, [lang]);


  return (
    <div>
      <Meta title={t("green-purchasing-policy")} description={t("green-purchasing-policy-0")} lang={lang}/>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/Green-Procurement.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("sustainability")}
              </div>
              <h1 className="c-caption-header">
                {t("green-purchasing-policy")}
              </h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <p>{t("green-purchasing-policy-0")}</p>
          <p style={{fontWeight: "bold"}}>{t("green-purchasing-policy-1")}</p>
          <p></p>
          <ul style={{ listStyle: "inside" }}>
            <li> {t("green-purchasing-policy-2")}</li>
            <li> {t("green-purchasing-policy-3")}</li>
            <li> {t("green-purchasing-policy-4")}</li>
            <li> {t("green-purchasing-policy-5")}</li>
            <li> {t("green-purchasing-policy-6")}</li>
            <li> {t("green-purchasing-policy-7")}</li>
            <li> {t("green-purchasing-policy-8")}</li>
            <li> {t("green-purchasing-policy-9")}</li>
            <li> {t("green-purchasing-policy-10")}</li>
            <li> {t("green-purchasing-policy-11")}</li>
            <li> {t("green-purchasing-policy-12")}</li>
          </ul>
          <p style={{fontWeight: "bold"}}>
            {t("green-purchasing-policy-13")}
            <br />
          </p>
            <p>{t("green-purchasing-policy-14")}</p>
            <p>{t("green-purchasing-policy-15")}</p>
            <p>{t("green-purchasing-policy-16")}</p>
            <p>{t("green-purchasing-policy-17")}</p>
        </div>
      </div>
    </div>
  );
};

export default GreenPurchase;
