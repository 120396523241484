import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Navbar } from "../../Components/Navbar/Navbar";
import "./ICS.styles.css";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import { Meta } from "../../Components/Helpers/Meta";

const InformationCommunity = () => {
    const { t } = useTranslation();
    const language = i18next.language;
    const {lang} =useParams();

    // useEffect(() => {
    //     document.title = "Contact - Cevher";
    //     if (lang) {
    //         i18next.changeLanguage(lang);
    //     }
    //     const updateTitle = () => {
    //         document.title = `${t("info-com-system-title")} - Cevher`;
    //       };
        
    //       updateTitle();
        
    //       const handleLanguageChanged = () => {
    //         updateTitle();
    //       };
        
    //       i18next.on("languageChanged", handleLanguageChanged);
        
    //       return () => {
    //         i18next.off("languageChanged", handleLanguageChanged);
    //       };
    //     }, [lang]);
    return (
        <div>
            <Meta title={t("info-com-system-title")} description={t("sektor-1")} lang={lang}/>
            <Navbar />
            <div className="c-wrapper">
                <div className="c-hero">
                    <div className="c-hero-background">
                        <picture>
                            <img
                                className="c-hero-image"
                                alt=""
                                src={require("../../assets/hero/Information-Community-System.jpg")}
                                style={{ top: "80%" }}
                            />
                        </picture>
                    </div>
                    <div className="c-hero-body">
                        <div className="c-about-caption">
                            <div
                                className="c-caption-section"
                                style={{ color: "#fff", textAlign: "center" }}
                            >
                                {t("corporate")}
                            </div>
                            <h1 className="c-caption-header">{t("information-community-system")}</h1>
                        </div>
                    </div>
                </div>
                <div
                    className="c-container"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: "2rem",
                        paddingBottom: "2rem",
                    }}
                >
    
                    <p style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "1rem" }}>
                        {t("company-information")}
                    </p>
                    
                    
                    <div className="info-table" style={{ display: "table", width: "100%" }}>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("company-type")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>{t("joint-stock-company")}</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>MERSIS:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>0206040650300017</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("directorate-of-trade-registry")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>İzmir</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("trade-registry-number")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>104150</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("trade-name")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>Cevher Jant Sanayii A.Ş.</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("address")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>Zafer Sb. Mh. Kürşad Sk. No:10 İç Kapı No:1 Gaziemir - İzmir</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("paid-capital-amount")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>100.000.000,00 TL</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("company-registrate-date")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>19.07.2002</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("tax-office")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>Gaziemir</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("tax-number")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>2060406503</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("sektor")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>{t("sektor-1")}</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("phone-number")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>0 232 298 20 00</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("fax-number")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>0 232 298 20 98</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("website")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>
                                <a href="http://www.cevher.com">www.cevher.com</a>
                            </div>
                        </div>
                    </div>
    
                   
                    <p style={{ fontSize: "1.5rem", fontWeight: "bold", marginTop: "3rem", marginBottom: "1rem" }}>
                        {t("board-of-directors")}
                    </p>
                    
                   
                    <div className="board-table" style={{ display: "table", width: "100%" }}>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>Haluk Özyavuz</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>{t("board-chairman")}</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>Ozan Batu</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>{t("vice-chairman")}</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>Barış Sivri</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>{t("board-member")}</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>Hilal Özyavuz</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>{t("board-member")}</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>Bahar Özyavuz</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>{t("board-member")}</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>Yılmaz Karakaş</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>{t("board-member")}</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>Mehmet Şencan</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>{t("independent-board-member")}</div>
                        </div>
                    </div>
    
                    
                    <p style={{ fontSize: "1.5rem", fontWeight: "bold", marginTop: "3rem", marginBottom: "1rem" }}>
                        {t("auditor")}
                    </p>
                    
                   
                    <div className="auditor-table" style={{ display: "table", width: "100%" }}>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("period")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>01.01.2023 - 31.12.2023</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("auditor-trade-name")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>KPMG Bağımsız Denetim ve Serbest Muhasebeci Mali Müşavirlik A.Ş</div>
                        </div>
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell", fontWeight: "bold", padding: "8px" }}>{t("address")}:</div>
                            <div style={{ display: "table-cell", padding: "8px" }}>Folkart Towers Adalet Mah. Manas Bulvarı No:39 B Blok K:35 Bayraklı/İzmir</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
    


};

export default InformationCommunity;