import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { Meta } from "../../Components/Helpers/Meta";

const ModernSlaveryPolicy = () => {
    const { t } = useTranslation();
    const language = i18next.language;
    const {lang} =useParams();
  
    
    // useEffect(() => {
    //   if (lang) {
    //       i18next.changeLanguage(lang);
    //   }
    //   const updateTitle = () => {
    //       document.title = `${t("slavery-policy-title")} - Cevher`;
    //     };
      
    //     updateTitle();
      
    //     const handleLanguageChanged = () => {
    //       updateTitle();
    //     };
      
    //     i18next.on("languageChanged", handleLanguageChanged);
      
    //     return () => {
    //       i18next.off("languageChanged", handleLanguageChanged);
    //     };
    //   }, [lang]);
    return (
        <div>
            <Meta title={t("slavery-policy-title")} description={t("slavery-policy-0")} lang={lang}/>
            <Navbar />
            <div className="c-wrapper">
                <div className="c-hero">
                    <div className="c-hero-background">
                        <picture>
                            <img
                                className="c-hero-image"
                                alt=""
                                src={require("../../assets/hero/modernslave.jpg")}
                            />
                        </picture>
                    </div>
                    <div className="c-hero-body">
                        <div className="c-about-caption">
                            <div
                                className="c-caption-section"
                                style={{ color: "rgb(189, 80, 80)", textAlign: "center" }}
                            >
                                {t("sustainability")}
                            </div>
                            <h1 className="c-caption-header" style={{ color: "rgb(189, 80, 80)" }}>
                                {t("slavery-policy-title")}
                            </h1>
                        </div>
                    </div>
                </div>
                <div
                    className="c-container"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#000",
                        paddingTop: "3rem",
                        paddingBottom: "3rem",
                    }}
                >

                    <p>
                        {t("slavery-policy-0")}
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("slavery-policy-title-1")}
                        </strong>
                    </p>
                    <p>
                        {t("slavery-policy-1")}
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("slavery-policy-title-2")}
                        </strong>
                    </p>
                    <p>
                        {t("slavery-policy-2.1")}
                        <ul style={{ marginTop:"10px", listStyleType:"circle"}}>
                            <li>{t("slavery-policy-2.2")}</li>
                            <li>{t("slavery-policy-2.3")}</li>
                            <li>{t("slavery-policy-2.4")}</li>
                            </ul>
                            {t("slavery-policy-2.5")}
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("slavery-policy-title-3")}
                        </strong>
                    </p>
                    <p>
                        {t("slavery-policy-3")}

                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("slavery-policy-title-4")}
                        </strong>
                    </p>
                    <p>
                        {t("slavery-policy-4")}
                    </p>

                    <div style={{  justifyContent:'center', alignItems:'center',marginTop:'20px'}}>
                    <h3>Haluk ÖZYAVUZ</h3>
                    <p>{t("slavery-policy-title-5")}</p>
                    <p>12.10.2021</p>
                    </div>
                
                </div>
            </div>
        </div>
    );
};

export default ModernSlaveryPolicy;
