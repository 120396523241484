import { Navigate, useParams, useNavigate } from "react-router-dom";
import i18next from "i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import { News_EN } from "../../Constants/News";
import "./NewPage.css";
import NotFound from "../404";
import { useEffect, useState } from "react";
import { Meta } from "../../Components/Helpers/Meta";

const New = () => {
  const { link, lang } = useParams();
  let insideLanguage=i18next.language;
  let language = lang!==undefined ? lang : insideLanguage;
  console.log(language);

  
  
  const [newItem, setNewItem] = useState(null);
  const [itemImg, setItemImg] = useState(null);
  const [itemTitle,setItemTitle]=useState(null);
  const navigate = useNavigate();

  useEffect(() => {
   
    // const foundItem = language === "en"
    //   ? News_EN.find((item) => item.link === link)
    //   : News_EN.find((item) => item.link_tr === link);

    const foundItem = News_EN.find((item) =>item.link  === link) || News_EN.find((item) => item.link_tr === link);

    setNewItem(foundItem);
    console.log(foundItem);
    setItemImg(foundItem.img);
    if (!foundItem) {
      window.history.replaceState({ status: 404 }, "", window.location.href);
    }
    if (language === "tr") {
      // navigate(`/tr/${foundItem.link_tr}`);
      i18next.changeLanguage("tr");
      setItemTitle(foundItem.title_tr);
    }
    if (language === "en") {
      // navigate(`/${foundItem.link}`);
      i18next.changeLanguage("en");
      setItemTitle(foundItem.title);
    }



  }, [link, language]);

  const imagesrc = itemImg;
  const fullUrl =`https://cevher.com${imagesrc}`;

  if (newItem) {
    return (
      <>
       <Meta title={itemTitle} description={itemTitle} lang={lang} metaimg={fullUrl} />
        <Navbar />
        <div className="c-wrapper">
          <div className="c-hero">
            <div className="c-hero-background">
              <picture>
                <img
                  className="c-hero-image"
                  alt=""
                  src={require("../../assets/hero/news.2f117a4c.jpg")}
                />
              </picture>
            </div>
          </div>
          <div className="c-container">
            <div className="c-new-page-row">
              <div className="c-new-page-col">
                <div
                  className="c-new-page-container"
                  style={{ textAlign: "center", marginTop: "3rem" }}
                >
                  <div className="c-new-page-title">
                    {insideLanguage === "en" ? newItem.title : newItem.title_tr}
                  </div>
                  <div
                    className="c-new-page-date"
                    style={{ marginTop: "1rem" }}
                  >
                    {newItem.date}
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: insideLanguage === "en" ? newItem.body : newItem.body_tr,
                  }}
                  style={{ marginTop: "3rem" }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
// 404 Page
  else {
    return (
     <NotFound />
  );
  }
};

export default New;
