import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Meta } from "../../Components/Helpers/Meta";

const IndustrialDesign = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const {lang} =useParams();

  
  // useEffect(() => {
  //   if (lang) {
  //       i18next.changeLanguage(lang);
  //   }
  //   const updateTitle = () => {
  //       document.title = `${t("values-title")} - Cevher`;
  //     };
    
  //     updateTitle();
    
  //     const handleLanguageChanged = () => {
  //       updateTitle();
  //     };
    
  //     i18next.on("languageChanged", handleLanguageChanged);
    
  //     return () => {
  //       i18next.off("languageChanged", handleLanguageChanged);
  //     };
  //   }, [lang]);

  return (
    <div>
      <Meta title={t("development-industrialdesign-title")} description={t("development-industrialdesign-2")} lang={lang}/>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/industrial-design.fb372162.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <h1 className="c-caption-header">
                {t("development-industrialdesign")}
              </h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <p>
            <strong style={{ textAlign: "start" }}>
              {t("development-industrialdesign-title")}
            </strong>
          </p>
          <p>{t("development-industrialdesign-1")}</p>
          <p>{t("development-industrialdesign-2")}</p>
        </div>
        <div className="c-container">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div
              className="c-social-img-wrapper"
              style={{
                textAlign: "center",
                position: "relative",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <img
                src={require("../../assets/content/industrial-design-1.8166b81f.png")}
                style={{ maxWidth: "100%", height: "auto" }}
                alt="Industrial Design 1"
              />
            </div>
            <div
              className="c-social-img-wrapper"
              style={{
                textAlign: "center",
                position: "relative",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <img
                src={require("../../assets/content/industrial-design-2.aa7c32ef.png")}
                style={{ maxWidth: "100%", height: "auto" }}
                alt="Industrial Design 2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustrialDesign;
