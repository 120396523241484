import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";



// export const metaUpdater = ({ t,title, description ,metaimg }) => {
//     // const { t } = useTranslation();

//         const newTitle = `${title} | Cevher ${t('group')}`;
//         document.title = newTitle;
//         const newDescription = t(description); 
//         // Update og:title
//         const ogTitleMeta = document.querySelector('meta[property="og:title"]');
//         if (ogTitleMeta) {
//           ogTitleMeta.setAttribute("content", newTitle);
//         } else {
//           const meta = document.createElement("meta");
//           meta.setAttribute("property", "og:title");
//           meta.setAttribute("content", newTitle);
//           document.head.appendChild(meta);
//         }

//         // Update og:description
//         const ogDescriptionMeta = document.querySelector('meta[property="og:description"]');
//         if (ogDescriptionMeta) {
//           ogDescriptionMeta.setAttribute("content", newDescription);
//         } else {
//           const meta = document.createElement("meta");
//           meta.setAttribute("property", "og:description");
//           meta.setAttribute("content", newDescription);
//           document.head.appendChild(meta);
//         }

//         // Update description
//         const descriptionMeta = document.querySelector('meta[name="description"]');
//         if (descriptionMeta) {
//           descriptionMeta.setAttribute("content", newDescription);
//         } else {
//           const meta = document.createElement("meta");
//           meta.setAttribute("name", "description");
//           meta.setAttribute("content", newDescription);
//           document.head.appendChild(meta);
//         }

// }


export const Meta = ({ title, description, metaimg, lang }) => {
  const { t } = useTranslation();


  useEffect(() => {
    if (lang && lang !== undefined) {
      i18next.changeLanguage(lang);
    }
  }, [lang]);



  useEffect(() => {
    // if (lang && lang !== undefined) {
    //   i18next.changeLanguage(lang);
    // }


    const newTitle = `${title} | Cevher ${t('group')}`;
    document.title = newTitle;
    const newDescription = t(description);

    const updateMeta = (name, property, content) => {
      let meta = document.querySelector(`meta[${name}="${property}"]`);
      if (meta) {
        meta.setAttribute("content", content);
      } else {
        meta = document.createElement("meta");
        meta.setAttribute(name, property);
        meta.setAttribute("content", content);
        document.head.appendChild(meta);
      }
    };

    updateMeta("property", "og:title", newTitle);
    updateMeta("property", "og:description", newDescription);
    updateMeta("name", "description", newDescription);
    if (metaimg) {
      updateMeta("property", "og:image", metaimg);
    }
  }, [title, description, t]);

  return null;
};


// export const Meta = ({ title, description, metaimg, lang }) => {
//   const { t } = useTranslation();
// useEffect(() => {
//   // document.title = "About - Cevher";
//   // document.title = `${t('about-title')} - Cevher ${t('group')}`;
//   if (lang) {
//     i18next.changeLanguage(lang);
// }
// const updateTitle = () => {
//   const newTitle = `${t(title)} | Cevher ${t('group')}`;
//   document.title = newTitle;
//   const newDescription = t(description);
//   // Update og:title
//   const ogTitleMeta = document.querySelector('meta[property="og:title"]');
//   if (ogTitleMeta) {
//     ogTitleMeta.setAttribute("content", newTitle);
//   } else {
//     const meta = document.createElement("meta");
//     meta.setAttribute("property", "og:title");
//     meta.setAttribute("content", newTitle);
//     document.head.appendChild(meta);
//   }

//   // Update og:description
//   const ogDescriptionMeta = document.querySelector('meta[property="og:description"]');
//   if (ogDescriptionMeta) {
//     ogDescriptionMeta.setAttribute("content", newDescription);
//   } else {
//     const meta = document.createElement("meta");
//     meta.setAttribute("property", "og:description");
//     meta.setAttribute("content", newDescription);
//     document.head.appendChild(meta);
//   }

//   // Update description
//   const descriptionMeta = document.querySelector('meta[name="description"]');
//   if (descriptionMeta) {
//     descriptionMeta.setAttribute("content", newDescription);
//   } else {
//     const meta = document.createElement("meta");
//     meta.setAttribute("name", "description");
//     meta.setAttribute("content", newDescription);
//     document.head.appendChild(meta);
//   }

// };

// updateTitle();

// const handleLanguageChanged = () => {
//   updateTitle();
// };

// i18next.on("languageChanged", handleLanguageChanged);

// return () => {
//   i18next.off("languageChanged", handleLanguageChanged);
// };
// }, [lang]);
// }





