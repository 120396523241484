import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./AfterMarket.css";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Meta } from "../../Components/Helpers/Meta";

const AfterMarket = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const {lang} =useParams();

  
  // useEffect(() => {
  //   if (lang) {
  //       i18next.changeLanguage(lang);
  //   }
  //   const updateTitle = () => {
  //       document.title = `${t("alloywheelsforaftermarket")} - Cevher`;
  //     };
    
  //     updateTitle();
    
  //     const handleLanguageChanged = () => {
  //       updateTitle();
  //     };
    
  //     i18next.on("languageChanged", handleLanguageChanged);
    
  //     return () => {
  //       i18next.off("languageChanged", handleLanguageChanged);
  //     };
  //   }, [lang]);

  return (
    <>
    <Meta title={t("alloywheelsforaftermarket")} description={t("alloywheelsforaftermarket")} lang={lang} />
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/header_afm[1199].jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("products")}
              </div>
              <h1 className="c-caption-header">
                {t("alloywheelsforaftermarket")}
              </h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
        >
          <div className="c-col--offset-lg-0 c-col--lg-12">
            <p>
              <strong>{t("products-afm-title")}</strong>
            </p>
            <p>{t("products-afm-1")}</p>
            <p>{t("products-afm-2")}</p>
          </div>
          <div className="c-aftermarket c-col--offset-lg-0 c-col--lg-12">
            <img
              src={require("../../assets/content/1-S2.jpg")}
              alt="AFM"
              className="afm-img"
            />
            <img
              src={require("../../assets/content/2-S3.jpg")}
              alt="AFM"
              className="afm-img"
            />
            <img
              src={require("../../assets/content/3-S4.jpg")}
              alt="AFM"
              className="afm-img"
            />
            <img
              src={require("../../assets/content/4-AE.jpg")}
              alt="AFM"
              className="afm-img"
            />
            <img
              src={require("../../assets/content/5-K1.jpg")}
              alt="AFM"
              className="afm-img"
            />
            <img
              src={require("../../assets/content/6-tinted_clear_coat_applications.jpg")}
              alt="AFM"
              className="afm-img"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AfterMarket;
