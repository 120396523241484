import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import Timeline from "../../Components/Timeline";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Meta } from "../../Components/Helpers/Meta";

const History = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const {lang} =useParams();

  // useEffect(() => {
  //   if (lang) {
  //     i18next.changeLanguage(lang).then(() => {
  //       metaUpdater({
  //         t,
  //         title: t("history-title"),
  //         description: "history-description",
  //       });
  //     });
  //   }
  // }, [lang, t]);


  return (
    <>
     <Meta title={t("history-title")} description={t("corporate-history-1955")} lang={lang} />
      <Navbar />
      <div className="c-hero">
        <div className="c-hero-background">
          <picture>
            <img
              className="c-hero-image"
              alt=""
              src={require("../../assets/hero/history.387483c7.jpg")}
            />
          </picture>
        </div>
        <div className="c-hero-body">
          <div className="c-about-caption">
            <div
              className="c-caption-section"
              style={{ color: "#fff", textAlign: "center" }}
            >
              {t("corporate")}
            </div>
            <h1 className="c-caption-header">{t("corporate-history")}</h1>
          </div>
        </div>
      </div>
      <div className="c-container">
        <div style={{ position: "relative", overflow: "hidden" }}>
          <div className="c-timeline-lines" />
          <Timeline scrollable={false} />
        </div>
      </div>
    </>
  );
};

export default History;
