import React from 'react';
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";

const NotFound = () => {
    const { t } = useTranslation();

    
    const notFoundContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 100px)', 
        textAlign: 'center',
        backgroundColor: '#ffffff',
        padding: '20px',
    };

    
    const notFoundContentStyle = {
        maxWidth: '600px',
        width: '100%',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    };

    
    const notFoundTitleStyle = {
        fontSize: '6rem',
        fontWeight: 'bold',
        color: '#71c8e6', 
        marginBottom: '10px',
    };

    
    const notFoundSubtitleStyle = {
        fontSize: '2rem',
        color: '#333',
        marginBottom: '20px',
    };

    
    const notFoundDescriptionStyle = {
        fontSize: '1.2rem',
        color: '#666',
        marginBottom: '30px',
    };

    
    const backHomeBtnStyle = {
        padding: '10px 20px',
        backgroundColor: '#3498db', 
        color: 'white',
        fontSize: '1rem',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    };

    
    const backHomeBtnHoverStyle = {
        backgroundColor: '#2980b9', 
    };

    
    const handleMouseEnter = (e) => {
        e.target.style.backgroundColor = backHomeBtnHoverStyle.backgroundColor;
    };

    const handleMouseLeave = (e) => {
        e.target.style.backgroundColor = backHomeBtnStyle.backgroundColor;
    };

    return (
        <div>
            <Navbar />
            <div style={notFoundContainerStyle}>
                <div style={notFoundContentStyle}>
                    <h1 style={notFoundTitleStyle}>404</h1>
                    <h2 style={notFoundSubtitleStyle}>{t("page-not-found")}</h2>
                    <p style={notFoundDescriptionStyle}>{t("page-not-exist")}</p>
                    <div>
                        <button 
                            style={backHomeBtnStyle}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => window.location.href = '/'}
                        >
                            {t("go-back-home")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
