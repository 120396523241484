import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./SustainabilityReport.css";
import { useParams } from "react-router-dom";
import { Meta } from "../../Components/Helpers/Meta";

const SustainabilityReport = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const {lang} =useParams();

  
  // useEffect(() => {
  //   if (lang) {
  //       i18next.changeLanguage(lang);
  //   }
  //   const updateTitle = () => {
  //       document.title = `${t("sustainabilityreport")} - Cevher`;
  //     };
    
  //     updateTitle();
    
  //     const handleLanguageChanged = () => {
  //       updateTitle();
  //     };
    
  //     i18next.on("languageChanged", handleLanguageChanged);
    
  //     return () => {
  //       i18next.off("languageChanged", handleLanguageChanged);
  //     };
  //   }, [lang]);
  return (
    <div>
      <Meta title={t("sustainabilityreport")} description={t("sustainability-report-desc")} lang={lang}/>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/sustainability-report.93d2f60c.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("sustainability")}
              </div>
              <h1 className="c-caption-header">{t("sustainabilityreport")}</h1>
            </div>
          </div>
        </div>
        <div className="c-container">
          <p>
            <br />
            <br />
            {t("sustainability-report-desc")}
            <br />
            <br />
          </p>
          <div className="c-row">

            <div className="c-col">
              <a
                href="https://www.cevher.com/static/docs/ASI-CertificateV3.pdf"
                className="c-button"
                style={{ marginTop: "1.5rem" }}
              >
                {t("sustainability-asiV3")}
              </a>
            </div>
            <div className="c-col">
              <a
                href="https://www.cevher.com/static/docs/ASI-CertificateV2.pdf"
                className="c-button"
                style={{ marginTop: "1.5rem" }}
              >
                {t("sustainability-asiV2")}
              </a>
            </div>
            <div className="c-col">
              <a
                href={
                  language === "en"
                    ? "https://www.cevher.com/static/docs/cevher_sustainability_report_2023.pdf"
                    : "https://www.cevher.com/static/docs/cevher_sustainability_report_2023.pdf"
                }
                className="c-button"
                style={{ marginTop: "1.5rem" }}
              >
                {t("sustainability-2023-report")}
              </a>
            </div>
            <div className="c-col">
              <a
                href={
                  language === "en"
                    ? "https://www.cevher.com/static/docs/cevher_sustainability_report_2022.pdf"
                    : "https://www.cevher.com/static/docs/cevher_sustainability_report_2022.pdf"
                }
                className="c-button"
                style={{ marginTop: "1.5rem" }}
              >
                {t("sustainability-2022-report")}
              </a>
            </div>
            <div className="c-col">
              <a
                href={
                  language === "en"
                    ? "https://www.cevher.com/static/docs/cevher_sustainability_report_2021[1559].pdf"
                    : "https://www.cevher.com/static/docs/cevher_surdurulebilirlik_raporu_2021[1558].pdf"
                }
                className="c-button"
                style={{ marginTop: "1.5rem" }}
              >
                {t("sustainability-2021-report")}
              </a>
            </div>
            <div className="c-col">
              <a
                href={
                  language === "en"
                    ? "https://www.cevher.com/static/docs/cevher_sustainability_report_2020.pdf"
                    : "https://www.cevher.com/static/docs/cevher_surdurulebilirlik_raporu_2020.pdf"
                }
                className="c-button"
                style={{ marginTop: "1.5rem" }}
              >
                {t("sustainability-2020-report")}
              </a>
            </div>
            <div className="c-col">
              <a
                href={
                  language === "en"
                    ? "https://www.cevher.com/static/docs/cevher_sustainability_report_2019.pdf"
                    : "https://www.cevher.com/static/docs/cevher_surdurulebilirlik_raporu_2019.pdf"
                }
                className="c-button"
                style={{ marginTop: "1.5rem" }}
              >
                {t("sustainability-2019-report")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SustainabilityReport;
