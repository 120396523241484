import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { Meta } from "../../Components/Helpers/Meta";

const Security = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const {lang} =useParams();

  
  // useEffect(() => {
  //   if (lang) {
  //       i18next.changeLanguage(lang);
  //   }
  //   const updateTitle = () => {
  //       document.title = `${t("informationsecuritypolicy")} - Cevher`;
  //     };
    
  //     updateTitle();
    
  //     const handleLanguageChanged = () => {
  //       updateTitle();
  //     };
    
  //     i18next.on("languageChanged", handleLanguageChanged);
    
  //     return () => {
  //       i18next.off("languageChanged", handleLanguageChanged);
  //     };
  //   }, [lang]);

  return (
    <div>
      <Meta title={t("informationsecuritypolicy")} description={t("informationsecuritypolicy")} lang={lang}/>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/information-security-policy.a954fd04.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("sustainability")}
              </div>
              <h1 className="c-caption-header">
                {t("informationsecuritypolicy")}
              </h1>
            </div>
          </div>
        </div>
        <div className="c-container" style={{ paddingTop: "3rem", paddingBottom: "3rem", width: '100%', position: 'relative', overflow: 'hidden' }}>
          <a
            href={require("../../assets/BGYS.jpg")}
          >
            <img
              src={require("../../assets/BGYS.jpg")}
              alt=""
              className="c-logo"
              style={{ width: '100%', height: 'auto', display: 'block' }}
            />
          </a>
        </div>



      </div>
    </div>
  );
};

export default Security;
