import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { Meta } from "../../Components/Helpers/Meta";

const SocialCompliance = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const {lang} =useParams();

  
  // useEffect(() => {
  //   if (lang) {
  //       i18next.changeLanguage(lang);
  //   }
  //   const updateTitle = () => {
  //       document.title = `${t("socialcompliancepolicy")} - Cevher`;
  //     };
    
  //     updateTitle();
    
  //     const handleLanguageChanged = () => {
  //       updateTitle();
  //     };
    
  //     i18next.on("languageChanged", handleLanguageChanged);
    
  //     return () => {
  //       i18next.off("languageChanged", handleLanguageChanged);
  //     };
  //   }, [lang]);

  return (
    <div>
      <Meta title={t("socialcompliancepolicy")} description={t("social-complience-1")} lang={lang}/>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/social-compliance-policy.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#000", textAlign: "center" }}
              >
                {t("sustainability")}
              </div>
              <h1 className="c-caption-header" style={{ color: "#000"}}>
                {t("socialcompliancepolicy")}
              </h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{
            display: "flex",
            flexDirection: "column",
            color: "#000",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >

      <p>
{t("social-complience-1")}
      </p>
          <p>
            <strong style={{ textAlign: "start" }}>
{t("social-complience-title-1")}
            </strong>
          </p>
      <p>
{t("social-complience-2")}
      </p>
          <p>
            <strong style={{ textAlign: "start" }}>
{t("social-complience-title-2")}
            </strong>
          </p>
      <p> 
{t("social-complience-3")}
      </p>
          <p>
            <strong style={{ textAlign: "start" }}>
{t("social-complience-title-3")}
            </strong>
          </p>
      <p> 
{t("social-complience-4")}

      </p>
          <p>
            <strong style={{ textAlign: "start" }}>
{t("social-complience-title-4")}
            </strong>
          </p>
      <p>
{t("social-complience-5")}
      </p>

          <p>
            <strong style={{ textAlign: "start" }}>
{t("social-complience-title-5")}
            </strong>
          </p>
      <p>
{t("social-complience-6")}

      </p>
          <p>
            <strong style={{ textAlign: "start" }}>
{t("social-complience-title-6")}
            </strong>
          </p>
      <p>
{t("social-complience-7")}
      </p>
          <p>
            <strong style={{ textAlign: "start" }}>
{t("social-complience-title-7")}
            </strong>
          </p>
            <p>
{t("social-complience-8")}
      </p>


          <p>
            <strong style={{ textAlign: "start" }}>
{t("social-complience-title-8")}
            </strong>
          </p>
    <p>
{t("social-complience-9")}
    </p>
          <p>
            <strong style={{ textAlign: "start" }}>
{t("social-complience-title-9")}
            </strong>
          </p>
    <p>
{t("social-complience-10")}
    </p>
          <p>
            <strong style={{ textAlign: "start" }}>
{t("social-complience-title-10")}
            </strong>
          </p>
    <p>
{t("social-complience-11")}
    </p>
          <p>
            <strong style={{ textAlign: "start" }}>
{t("social-complience-title-11")}
            </strong>
          </p>
    <p>
{t("social-complience-12")}
    </p>
          <p>
            <strong style={{ textAlign: "start" }}>
{t("social-complience-title-12")}
            </strong>
          </p>
    <p>
{t("social-complience-13")}
    </p>
        </div>
      </div>
    </div>
  );
};

export default SocialCompliance;
