import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import { Meta } from "../../Components/Helpers/Meta";

const Health = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const {lang} =useParams();

  
  // useEffect(() => {
  //   if (lang) {
  //       i18next.changeLanguage(lang);
  //   }
  //   const updateTitle = () => {
  //       document.title = `${t("sustainability-health")} - Cevher`;
  //     };
    
  //     updateTitle();
    
  //     const handleLanguageChanged = () => {
  //       updateTitle();
  //     };
    
  //     i18next.on("languageChanged", handleLanguageChanged);
    
  //     return () => {
  //       i18next.off("languageChanged", handleLanguageChanged);
  //     };
  //   }, [lang]);

  return (
    <div>
      <Meta title={t("sustainability-health")} description={t("sustainability-health-title")} lang={lang}/>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/occupational-health-and-safety-policy.3fb5dcaf.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("sustainability")}
              </div>
              <h1 className="c-caption-header">{t("sustainability-health")}</h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <p>
            <strong style={{ textAlign: "start" }}>
              {t("sustainability-health-title")}
            </strong>
            <br />
            <br />
            {t("sustainability-health-1")}
            <br />
            <br />
            {t("sustainability-health-2")}
            <br />
            <br />
          </p>
          <p>
            <strong style={{ textAlign: "start" }}>
              {t("sustainability-health-ourprinciples")}
            </strong>
            <br />
            <br />{t("sustainability-health-ourprinciples-1")}
            <br />
            <br />- {t("sustainability-health-ourprinciples-2")}
            <br />-{t("sustainability-health-ourprinciples-3")}
            <br />- {t("sustainability-health-ourprinciples-4")}
            <br />- {t("sustainability-health-ourprinciples-5")}
            <br />- {t("sustainability-health-ourprinciples-6")}
            <br />- {t("sustainability-health-ourprinciples-7")}
          </p>
          <p>
            <strong style={{ textAlign: "start" }}>
              {t("sustainability-health-ourobjectives")}
            </strong>
            <br />
            <br />-{t("sustainability-health-ourobjectives-1")}
            <br />- {t("sustainability-health-ourobjectives-2")}
            <br />-{t("sustainability-health-ourobjectives-3")}
            <br />-{t("sustainability-health-ourobjectives-4")}
          </p>
          <p>
            <br />
            <br />
            <strong style={{ textAlign: "start" }}>
              {t("sustainability-health-activities")}
            </strong>
            <br />
            <br />
            {t("sustainability-health-activities-1")}
            <br />
            <ul style={{ listStyle: "inside" }}>
              <li>{t("sustainability-health-activities-2")}</li>
              <li>{t("sustainability-health-activities-3")}</li>
              <li>{t("sustainability-health-activities-4")}</li>
              <li>{t("sustainability-health-activities-5")}</li>
              <li>{t("sustainability-health-activities-6")}</li>
              <li>{t("sustainability-health-activities-7")}</li>
              <li>{t("sustainability-health-activities-8")}</li>
              <li>{t("sustainability-health-activities-9")}</li>
            </ul>
            {t("sustainability-health-regulations")}
            <br />
            <ul style={{ listStyle: "inside" }}>
              <li>{t("sustainability-health-regulations-1")}</li>
              <li>{t("sustainability-health-regulations-2")}</li>
              <li>{t("sustainability-health-regulations-4")}</li>
              <li>{t("sustainability-health-regulations-5")}</li>
              <li>{t("sustainability-health-regulations-6")}</li>
              <li>{t("sustainability-health-regulations-7")}</li>
              <li>{t("sustainability-health-regulations-8")}</li>
              <li>{t("sustainability-health-regulations-9")}</li>
              <li>{t("sustainability-health-regulations-10")}</li>
              <li>{t("sustainability-health-regulations-11")}</li>
              <li>{t("sustainability-health-regulations-12")}</li>
              <li>{t("sustainability-health-regulations-13")}</li>
              <li>{t("sustainability-health-regulations-14")}</li>
              <li>{t("sustainability-health-regulations-15")}</li>
              <li>{t("sustainability-health-regulations-16")}</li>
              <li>{t("sustainability-health-regulations-17")}</li>
              <li>{t("sustainability-health-regulations-18")}</li>
              <li>{t("sustainability-health-regulations-19")}</li>
              <li>{t("sustainability-health-regulations-20")}</li>
              <li>{t("sustainability-health-regulations-21")}</li>
              <li>{t("sustainability-health-regulations-22")}</li>
              <li>{t("sustainability-health-regulations-23")}</li>
              <li>{t("sustainability-health-regulations-24")}</li>
              <li>{t("sustainability-health-regulations-25")}</li>
              <li>{t("sustainability-health-regulations-26")}</li>
            </ul>
          </p>
        </div>













        <div className="c-container">
                    <div className="c-col--offset-lg-2 c-col--lg-8">
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                                justifyContent: "center",
                                gap: 8,
                            }}
                        >
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/Cevher_Health_EN.jpg")
                                        : require("../../assets/certificates/Cevher_Health_TR.jpg")
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/Cevher_Health_EN.jpg")
                                            : require("../../assets/certificates/Cevher_Health_TR.jpg")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                            
                            
                        </div>

                    </div>
                </div>
      </div>
    </div>
  );
};

export default Health;
