import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./OEM.styles.css";
import i18next from "i18next";
import { Meta } from "../../Components/Helpers/Meta";

const OEM = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const {lang} =useParams();

  
  // useEffect(() => {
  //   if (lang) {
  //       i18next.changeLanguage(lang);
  //   }
  //   const updateTitle = () => {
  //       document.title = `OEM- Cevher`;
  //     };
    
  //     updateTitle();
    
  //     const handleLanguageChanged = () => {
  //       updateTitle();
  //     };
    
  //     i18next.on("languageChanged", handleLanguageChanged);
    
  //     return () => {
  //       i18next.off("languageChanged", handleLanguageChanged);
  //     };
  //   }, [lang]);

  return (
    <div>
      <Meta title={t("products-oem-title")} description={t("products-oem-1-desc")} lang={lang}/>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/header_oem_3[1341].jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("products")}
              </div>
              <h1 className="c-caption-header">{t("alloywheelsforoem")}</h1>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}>
          <div className="c-container">
            <div className="c-col--offset-lg-0 c-col--lg-12">
              <p>
                <strong>{t("products-oem-title")}</strong>
              </p>
              <p>{t("products-oem-desc")}</p>
            </div>
          </div>
        </div>
        <div className="c-container">
          <div
            className="c-row row--no-gutter u-flex-row-reverse "
            style={{ paddingTop: "4rem" }}
          >
            <div className="col c-col--lg-6">
              <div className="c-caption-container" style={{ padding: 0 }}>
                <div className="c-caption c-oem-caption">
                  <div className="c-caption-title">
                    {t("products-oem-1-title")}
                  </div>
                  <div className="c-caption-content">
                    {t("products-oem-1-desc")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/content/product-1.c972327b.jpg")}
                  alt="Product-1"
                  className="c-products-img"
                />
              </div>
            </div>
          </div>
          <div className="c-row row--no-gutter " style={{ paddingTop: "4rem" }}>
            <div className="col c-col--lg-6">
              <div className="c-caption c-oem-caption">
                <div className="c-caption-title">
                  {t("products-oem-2-title")}
                </div>
                <div className="c-caption-content">
                  {t("products-oem-2-desc")}
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/content/product-2.e1699e55.jpg")}
                  alt="Product-1"
                  className="c-products-img"
                />
              </div>
            </div>
          </div>
          <div
            className="c-row row--no-gutter u-flex-row-reverse"
            style={{ paddingTop: "4rem" }}
          >
            <div className="col c-col--lg-6">
              <div className="c-caption c-oem-caption">
                <div className="c-caption-title">
                  {t("products-oem-3-title")}
                </div>
                <div className="c-caption-content">
                  {t("products-oem-3-desc")}
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/content/product-3.a7530c0e.jpg")}
                  alt="Product-1"
                  className="c-products-img"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{ paddingTop: "2rem", paddingBottom: "2rem", width: "100%" }}
        >
          <div
            className="c-hero"
            style={{
              width: "100%",
              height: "500px",
              padding: 0,
              justifyContent: "left",
            }}
          >
            <div className="c-hero-background">
              <img
                className="c-hero-image"
                style={{ width: "auto" }}
                alt=""
                src={require("../../assets/content/production-process.e4aa169a.jpg")}
              />
            </div>
            <div className="c-hero-body">
              <div className="c-caption">
                <div className="c-caption-section">
                  {t("developmentandproduction")}
                </div>
                <div className="c-caption-title">{t("production")}</div>
                <div className="c-caption-content">
                  {t("products-oem-discover")}
                </div>
              </div>
              <div
                style={{ marginTop: "1.5rem", justifyContent: "flex-start" }}
              >
                <Link
                  to="/development-and-production/production"
                  className="c-button"
                >
                  {t("products-oem-information")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OEM;
