import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { Meta } from "../../Components/Helpers/Meta";

const Energy = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const {lang} =useParams();

  
  // useEffect(() => {
  //   if (lang) {
  //       i18next.changeLanguage(lang);
  //   }
  //   const updateTitle = () => {
  //       document.title = `${t("energyactivities")} - Cevher`;
  //     };
    
  //     updateTitle();
    
  //     const handleLanguageChanged = () => {
  //       updateTitle();
  //     };
    
  //     i18next.on("languageChanged", handleLanguageChanged);
    
  //     return () => {
  //       i18next.off("languageChanged", handleLanguageChanged);
  //     };
  //   }, [lang]);

  return (
    <div>
      <Meta title={t("energyactivities")} description={t("sustainability-energy-desc")} lang={lang}/>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/energy-management-policy.ff225fd1.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("sustainability")}
              </div>
              <h1 className="c-caption-header">
                {t("energyactivities")}
              </h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <p>
            {t("sustainability-energy-17")}
            <br />
            <br />
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-energy-18")}</li>
            <li>{t("sustainability-energy-19")}</li>
            <li>{t("sustainability-energy-20")}</li>
            <li>{t("sustainability-energy-21")}</li>
            <li>{t("sustainability-energy-22")}</li>
            <li>{t("sustainability-energy-23")}</li>
            <li>{t("sustainability-energy-24")}</li>
            <li>{t("sustainability-energy-25")}</li>
            <li>{t("sustainability-energy-26")}</li>
            <li>{t("sustainability-energy-27")}</li>
            <li>{t("sustainability-energy-28")}</li>
            <li>{t("sustainability-energy-29")}</li>
            <li>{t("sustainability-energy-30")}</li>
            <li>{t("sustainability-energy-31")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Energy;
